import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import AppMini from "./AppMini";

const urlParams = new URLSearchParams(window.location.search);
const shouldMini = urlParams.get("mini") === "1";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>{shouldMini ? <AppMini /> : <App />}</React.StrictMode>
);
